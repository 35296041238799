import request from '@/api/request.js'

/**
 * @Description: 取货单
 */

// 分页查询
export function pageWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsGoodsOrder(data) {
    return request({
        url: '/api/wmsGoodsOrder/batchDelete',
        method: 'POST',
        data
    })
}

