<template>
  <div>
    
    <div class="contents">
      <submenu curbg=0></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="线上订单号" style="margin-bottom:10px">

                  <el-input v-model="searchForm.onlineOrderId" clearable  style="width:190px"
                    placeholder="请输入线上订单号"></el-input>
                </el-form-item>

                <el-form-item label="商品名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.spuName" clearable  style="width:190px"
                    placeholder="请输入商品名称"></el-input>
                </el-form-item>

                <el-form-item label="骑手名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.driverName" clearable  style="width:190px"
                    placeholder="请输入骑手名称"></el-input>
                </el-form-item>

                <el-form-item label="打印状态" style="margin-bottom:10px">

                    <el-select v-model="searchForm.printStatus"
                    clearable
                    filterable
                    placeholder="请选择打印状态">
                        <el-option
                              v-for="item in enums['WmsGoodsOrderPrintStatus'].filter(it => it.searchable)"
                              :key="item.value"
                              :label="item.name"
                              :value="item.value">
                            </el-option>
                    </el-select>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <el-button type="primary" class="tbBtn" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>

        <vxe-column
            field="orderId"
            title="发货单号"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.orderId}}
          </template>
        </vxe-column>
        <vxe-column
            field="onlineOrderId"
            title="线上订单号"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.onlineOrderId}}
          </template>
        </vxe-column>
        <vxe-column
            field="spuName"
            title="商品名称"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.spuName}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuName"
            title="规格"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.skuName}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuCode"
            title="商品编码"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuCode}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuPrice"
            title="商品价格"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuPrice}}
          </template>
        </vxe-column>
        <vxe-column
            field="skuPic"
            title="图片"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.skuPic}}
          </template>
        </vxe-column>
        <vxe-column
            field="driverName"
            title="骑手名称"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.driverName}}
          </template>
        </vxe-column>
        <vxe-column
            field="supplierName"
            title="供应商名称"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.supplierName}}
          </template>
        </vxe-column>
        <vxe-column
            field="batchNo"
            title="批次号"
            min-width="60"
        >
          <template slot-scope="scope">
                {{scope.row.batchNo}}
          </template>
        </vxe-column>

        <vxe-column
            field="printStatus"
            title="打印状态"
            min-width="80"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsGoodsOrderPrintStatus'] && enumMaps['WmsGoodsOrderPrintStatus'][scope.row.printStatus]}}
          </template>
        </vxe-column>
        <vxe-column
            field="printCount"
            title="打印次数"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.printCount}}
          </template>
        </vxe-column>
        <vxe-column
            field="startingName"
            title="取货点名称"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.startingName}}
          </template>
        </vxe-column>
        <vxe-column
            field="endingName"
            title="送货点名称"
            min-width="100"
        >
          <template slot-scope="scope">
                {{scope.row.endingName}}
          </template>
        </vxe-column>
        <vxe-column
            field="serial"
            title="序号"
            min-width="50"
        >
          <template slot-scope="scope">
                {{scope.row.serial}}
          </template>
        </vxe-column>
        <vxe-column
            field="status"
            title="状态"
            min-width="100"
        >
          <template slot-scope="scope">
                {{enumMaps['WmsGoodsOrderStatus'] && enumMaps['WmsGoodsOrderStatus'][scope.row.status]}}
          </template>
        </vxe-column>
        <vxe-column
            field="createTime"
            title="创建时间"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.createTime}}
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="100">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>

                  <!-- <el-button class="c-button" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button> -->

             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <goodsorder-add ref="wmsGoodsOrderAdd" @success="getTopSearch"></goodsorder-add>
    <goodsorder-detail ref="wmsGoodsOrderDetail" @success="getTopSearch"></goodsorder-detail>
    <goodsorder-edit ref="wmsGoodsOrderEdit" @success="getTopSearch"></goodsorder-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import submenu from "@/components/submenu"
import backImg from "@/assets/logofang.png"
import goodsorderAdd from "./wmsGoodsOrderAdd"
import goodsorderDetail from "./wmsGoodsOrderDetail"
import goodsorderEdit from "./wmsGoodsOrderEdit"

import { pageWmsGoodsOrder, detailWmsGoodsOrder, delWmsGoodsOrder, batchDeleteWmsGoodsOrder } from "@/api/wms/wmsGoodsOrder"
import { getEnums } from "@/api/common"

let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsGoodsOrder',
  components: { submenu, goodsorderAdd, goodsorderDetail, goodsorderEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
        WmsGoodsOrderPrintStatus: [],
        WmsGoodsOrderStatus: [],
      },
      enumMaps: {},
      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        this.multipleSelection = this.$refs.table.getCheckboxRecords()
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsGoodsOrder(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsGoodsOrderAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsGoodsOrderDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsGoodsOrderEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsGoodsOrder(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>