<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @close="onClose"
    title="取货单详情"
    :visible.sync="visible"
    width="900px"
    >

      <el-descriptions class="margin-top" :column="3"
      border>


            <el-descriptions-item label="ID">
            {{ row.id }}
            </el-descriptions-item>

            <el-descriptions-item label="发货单号">
            {{ row.orderId }}
            </el-descriptions-item>

            <el-descriptions-item label="线上订单号">
            {{ row.onlineOrderId }}
            </el-descriptions-item>

            <el-descriptions-item label="商品名称">
            {{ row.spuName }}
            </el-descriptions-item>

            <el-descriptions-item label="规格名称">
            {{ row.skuName }}
            </el-descriptions-item>

            <el-descriptions-item label="SKU编码">
            {{ row.skuCode }}
            </el-descriptions-item>

            <el-descriptions-item label="SKU价格">
            {{ row.skuPrice }}
            </el-descriptions-item>

            <el-descriptions-item label="SKU图片">
            {{ row.skuPic }}
            </el-descriptions-item>

            <el-descriptions-item label="骑手名称">
            {{ row.driverName }}
            </el-descriptions-item>

            <el-descriptions-item label="骑手ID">
            {{ row.driverId }}
            </el-descriptions-item>

            <el-descriptions-item label="供应商ID">
            {{ row.supplierId }}
            </el-descriptions-item>

            <el-descriptions-item label="供应商名称">
            {{ row.supplierName }}
            </el-descriptions-item>

            <el-descriptions-item label="批次号">
            {{ row.batchNo }}
            </el-descriptions-item>

            <el-descriptions-item label="当前页码（批次）">
            {{ row.pageNo }}
            </el-descriptions-item>

            <el-descriptions-item label="总页码（批次）">
            {{ row.totalPage }}
            </el-descriptions-item>

              <el-descriptions-item label="固化标识">
                {{row.persistentFlag == 0 ? '否': '是'}}
              </el-descriptions-item>

            <el-descriptions-item label="打印状态" v-if="enumMaps['WmsGoodsOrderPrintStatus']">
                 {{enumMaps['WmsGoodsOrderPrintStatus'][row.printStatus]}}
            </el-descriptions-item>

            <el-descriptions-item label="打印次数">
            {{ row.printCount }}
            </el-descriptions-item>

            <el-descriptions-item label="送货地址">
            {{ row.endingAddress }}
            </el-descriptions-item>

            <el-descriptions-item label="取货点名称">
            {{ row.startingName }}
            </el-descriptions-item>

            <el-descriptions-item label="送货点名称">
            {{ row.endingName }}
            </el-descriptions-item>

            <el-descriptions-item label="送货点电话">
            {{ row.endingPhone }}
            </el-descriptions-item>

            <el-descriptions-item label="取货点电话">
            {{ row.startingPhone }}
            </el-descriptions-item>

            <el-descriptions-item label="拿货序号">
            {{ row.serial }}
            </el-descriptions-item>

            <el-descriptions-item label="取货单状态" v-if="enumMaps['WmsGoodsOrderStatus']">
                 {{enumMaps['WmsGoodsOrderStatus'][row.status]}}
            </el-descriptions-item>

            <el-descriptions-item label="送货地址经度">
            {{ row.endingLongitude }}
            </el-descriptions-item>

            <el-descriptions-item label="送货地址纬度">
            {{ row.endingLatitude }}
            </el-descriptions-item>

            <el-descriptions-item label="取货地址">
            {{ row.startingAddress }}
            </el-descriptions-item>

            <el-descriptions-item label="取货地址纬度">
            {{ row.startingLatitude }}
            </el-descriptions-item>

            <el-descriptions-item label="取货地址经度">
            {{ row.startingLongitude }}
            </el-descriptions-item>


            <el-descriptions-item label="创建时间">
            {{ row.createTime }}
            </el-descriptions-item>




      </el-descriptions>


      <div slot="footer">
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums } from "@/api/common"
import { detailWmsGoodsOrder } from "@/api/wms/wmsGoodsOrder"

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
        visible: false,
        row: {
            id: '',
            orderId: '',
            onlineOrderId: '',
            spuName: '',
            skuName: '',
            skuCode: '',
            skuPrice: '',
            skuPic: '',
            driverName: '',
            driverId: '',
            supplierId: '',
            supplierName: '',
            batchNo: '',
            pageNo: '',
            totalPage: '',
            persistentFlag: '',
            printStatus: '',
            printCount: '',
            endingAddress: '',
            startingName: '',
            endingName: '',
            endingPhone: '',
            startingPhone: '',
            serial: '',
            status: '',
            endingLongitude: '',
            endingLatitude: '',
            startingAddress: '',
            startingLatitude: '',
            startingLongitude: '',
            createTime: '',
        },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsGoodsOrderPrintStatus')
          names.push('WmsGoodsOrderStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    detail() {
        const param = {
            id: this.row.id
        }
        detailWmsGoodsOrder(param).then(res=>{
          if(res.code === 200) {
            this.row = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    onOpen(row) {
        console.log("onOpen", row)
        this.visible = true
        this.row = row
        this.detail()
    },
    onClose() {
    },
    close() {
      this.visible = false
    },
  }
}

</script>
<style scoped>
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
