
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑取货单"
    :visible.sync="visible"
    width="600px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="24">
        <el-form-item label="取货单状态" prop="status">
                <el-select v-model="row.status"
                :disabled="false"
                clearable
                placeholder="请选择取货单状态">
                    <el-option
                          v-for="item in enums['WmsGoodsOrderStatus']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="送货地址经度" prop="endingLongitude">
              <el-input-number v-model="row.endingLongitude"
                :disabled="false"
                placeholder="请输入送货地址经度" clearable
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="送货地址纬度" prop="endingLatitude">
              <el-input-number v-model="row.endingLatitude"
                :disabled="false"
                placeholder="请输入送货地址纬度" clearable
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="供应商ID" prop="supplierId">
              <el-input v-model="row.supplierId" placeholder="请输入供应商ID" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="供应商名称" prop="supplierName">
              <el-input v-model="row.supplierName" placeholder="请输入供应商名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="取货地址" prop="startingAddress">
              <el-input v-model="row.startingAddress" placeholder="请输入取货地址" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="取货地址纬度" prop="startingLatitude">
              <el-input-number v-model="row.startingLatitude"
                :disabled="false"
                placeholder="请输入取货地址纬度" clearable
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="取货地址经度" prop="startingLongitude">
              <el-input-number v-model="row.startingLongitude"
                :disabled="false"
                placeholder="请输入取货地址经度" clearable
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="SKU编码" prop="skuCode">
              <el-input v-model="row.skuCode" placeholder="请输入SKU编码" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="规格名称" prop="skuName">
              <el-input v-model="row.skuName" placeholder="请输入规格名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="商品名称" prop="spuName">
              <el-input v-model="row.spuName" placeholder="请输入商品名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="线上订单号" prop="onlineOrderId">
              <el-input v-model="row.onlineOrderId" placeholder="请输入线上订单号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="订单号" prop="orderId">
              <el-input v-model="row.orderId" placeholder="请输入订单号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="打印次数" prop="printCount">
              <el-input-number v-model="row.printCount"
                :disabled="false"
                placeholder="请输入打印次数" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="骑手ID" prop="driverId">
              <el-input v-model="row.driverId" placeholder="请输入骑手ID" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="骑手名称" prop="driverName">
              <el-input v-model="row.driverName" placeholder="请输入骑手名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="批次号" prop="batchNo">
              <el-input v-model="row.batchNo" placeholder="请输入批次号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="送货地址" prop="endingAddress">
              <el-input v-model="row.endingAddress" placeholder="请输入送货地址" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="当前页码（批次）" prop="pageNo">
              <el-input-number v-model="row.pageNo"
                :disabled="false"
                placeholder="请输入当前页码（批次）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="总页码（批次）" prop="totalPage">
              <el-input-number v-model="row.totalPage"
                :disabled="false"
                placeholder="请输入总页码（批次）" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="打印状态" prop="printStatus">
                <el-select v-model="row.printStatus"
                :disabled="false"
                clearable
                placeholder="请选择打印状态">
                    <el-option
                          v-for="item in enums['WmsGoodsOrderPrintStatusEnum']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="固化标识" prop="persistentFlag">
                <el-radio-group v-model="row.persistentFlag" 
                :disabled="false"                
                size="mini">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
               </el-radio-group>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="取货点名称" prop="startingName">
              <el-input v-model="row.startingName" placeholder="请输入取货点名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="送货点名称" prop="endingName">
              <el-input v-model="row.endingName" placeholder="请输入送货点名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="送货点电话" prop="endingPhone">
              <el-input v-model="row.endingPhone" placeholder="请输入送货点电话" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="取货点电话" prop="startingPhone">
              <el-input v-model="row.startingPhone" placeholder="请输入取货点电话" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="拿货序号" prop="serial">
              <el-input v-model="row.serial" placeholder="请输入拿货序号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="商品图片" prop="skuPic">
              <el-input v-model="row.skuPic" placeholder="请输入商品图片" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
        <el-form-item label="商品价格" prop="skuPrice">
              <el-input-number v-model="row.skuPrice"
                :disabled="false"
                placeholder="请输入商品价格" clearable
                :step="0.001"
                :precision="3"
                :controls="false"
                :style="{width: '200px'}"></el-input-number>
        </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsGoodsOrder, listWmsGoodsOrder } from "@/api/wms/wmsGoodsOrder"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            status: null,
            endingLongitude: null,
            endingLatitude: null,
            supplierId: null,
            supplierName: null,
            startingAddress: null,
            startingLatitude: null,
            startingLongitude: null,
            skuCode: null,
            skuName: null,
            spuName: null,
            onlineOrderId: null,
            orderId: null,
            printCount: null,
            driverId: null,
            driverName: null,
            batchNo: null,
            endingAddress: null,
            pageNo: null,
            totalPage: null,
            printStatus: null,
            persistentFlag: null,
            startingName: null,
            endingName: null,
            endingPhone: null,
            startingPhone: null,
            serial: null,
            skuPic: null,
            skuPrice: null,
      },
      rules: {
            status: [
                { required: true, message: '请输入取货单状态!'},
            ],
            supplierId: [
                { required: true, message: '请输入供应商ID!'},
            ],
            supplierName: [
                { required: true, message: '请输入供应商名称!'},
            ],
            startingAddress: [
                { required: true, message: '请输入取货地址!'},
            ],
            spuName: [
                { required: true, message: '请输入商品名称!'},
            ],
            onlineOrderId: [
                { required: true, message: '请输入线上订单号!'},
            ],
            orderId: [
                { required: true, message: '请输入订单号!'},
            ],
            printCount: [
                { required: true, message: '请输入打印次数!'},
            ],
            driverId: [
                { required: true, message: '请输入骑手ID!'},
            ],
            endingAddress: [
                { required: true, message: '请输入送货地址!'},
            ],
            pageNo: [
                { required: true, message: '请输入当前页码（批次）!'},
            ],
            totalPage: [
                { required: true, message: '请输入总页码（批次）!'},
            ],
            printStatus: [
                { required: true, message: '请输入打印状态!'},
            ],
            persistentFlag: [
                { required: true, message: '请输入固化标识!'},
            ],
            serial: [
                { required: true, message: '请输入拿货序号!'},
            ],
            skuPrice: [
                { required: true, message: '请输入商品价格!'},
            ],
      },
        enums: {},
        enumMaps: {},
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsGoodsOrderStatus')
          names.push('WmsGoodsOrderPrintStatusEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsGoodsOrder(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
</style>
